import React from 'react'
import cx from 'classnames'
import Layout from 'components/Layout'
import SEO from 'components/seo'

import Button from "components/Button"
import Accordion from "components/Accordion"
import styles from './programs.module.scss'

const ProgramsPage = () => (
  <Layout className={styles.page}>
    <SEO title="Programs" />
    <section className={cx(styles.root, "pgHead")}>
      <div className={cx(styles.root, "content")}>
        <h1 className={cx(styles.title, "boldColor content-max-width")}>
          We are working to meet the daily meal needs of our local community.
        </h1>
      </div>
    </section>
    <div className={cx(styles.root, "content")}>
      <section className={cx(styles.section, styles.mentorshipSection)}>
        <img
          src="https://wp.afrodeli.com/wp-content/uploads/2022/01/feedingcommunity_bpkq6u.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community & MN Central Kitchen"
        />

        <h2 className={cx(styles.subTitle, "content-max-width")}>
          MN Central Kitchen Partnership
        </h2>
        <p>
          We partnered with MN Central Kitchen, an initiative of Second Harvest Heartland,
          to serve needed culturally-appropriate meals to our neighbors across the Twin Cities.
          With their support, we have been able to serve even more people across the metro and
          we plan to continue serving throughout 2022.
        </p>
        <div>
          <Button className="mt--lg" href="https://secure.2harvest.org/site/Donation2?df_id=24589&24589.donation=form1#.Yi19AhPMKda">
            Help Feed Neighbors
          </Button>
        </div>
      </section>
<section
        className={cx("mt--xl", styles.section, styles.mentorshipSection)}
      >        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611851858/seniorholdingmeal_rgdwbs_bbatqk.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Meals on Wheels Program"
        />

        <h2 className={cx(styles.subTitle, "content-max-width")}>
          Meals on Wheels Program
        </h2>
        <p>
          We partnered with Metro Meals on Wheels to serve seniors in the Twin
          Cities who need halal dietary options and other culturally-appropriate
          meals. The pandemic has only exacerbated the number of vulnerable
          seniors facing food insecurity. The need is growing and we want to able to
        </p>
        {/* <div>
          <Button className="mt--lg" href="/donate">
            Support our Meals on Wheels Program
          </Button>
        </div> */}
      </section>

      <section
        className={cx("mt--xl", styles.section, styles.mentorshipSection)}
      >
        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611848922/PXL_20201008_145124242.PORTRAIT-01.COVER_egzcfw.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Home Delivered Meals Program"
        />
        <h2 className={cx(styles.subTitle, "content-max-width")}>
          Home Delivered Meals Program
        </h2>
        <p>
          We have been proud to serve as one of Ramsey County's partners in
          their Home Meal Delivery Program, providing Ramsey County residents
          impacted by the COVID-19 pandemic up to 21 prepared meals delivered to
          your home for free each week. With your support, we can distribute meals to community members in other counties in the metro too.
        </p>
        {/* <div>
          <Button className="mt--lg" href="/donate">
            Support our Home Delivered Programs
          </Button>
        </div> */}
      </section>

      <section
        className={cx("mt--xl", styles.section, styles.mentorshipSection)}
      >
        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611637045/fc-usbankdelivery_cwkoqd.png"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Meals for Heroes Program"
        />
        <h2 className={cx(styles.subTitle, "content-max-width")}>
          Meals for Heroes
        </h2>
        <p>
          We have worked with community members and local
          companies of all sizes to sponsor food and catering for heroes of all
          types. We have been proud to serve hospital staff, emergency services,
          educational staff, and more as a small token of our appreciation for
          the work they do every day. We want to partner with more local
          businesses and individuals to keep thanking the heroes in our lives.
        </p>
        {/* <div>
          <Button className="mt--lg" href="/donate">
            Sponsor Meals for Heroes
          </Button>
        </div> */}
      </section>

      <section
        className={cx("mt--xl", styles.section, styles.mentorshipSection)}
      >
        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611851385/EZsIlPkWoAEiVEg_mj1r9l.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Meals for Voters & Volunteers"
        />
        <h2 className={cx(styles.subTitle, "content-max-width")}>
          Meals for Voters & Volunteers
        </h2>
        <p>
          We love to support civic participation and volunteerism of all kinds
          and our partnership with World Central Kitchen has allowed us to give
          back in more ways. From voters in line for this past year's election
          to the volunteers on the street, we've served thousands of our
          neighbors doing their part for our community. We will continue to do
          so to encourage more participation, more connections, and ultimately,
          more progress for all of us.
        </p>
        {/* <div>
          <Button className="mt--lg" href="/donate">
            Support Meals for Voters & Volunteers
          </Button>
        </div> */}
      </section>

      <section
        className={cx("mt--xl", styles.section, styles.mentorshipSection)}
      >
        <img
          src="https://res.cloudinary.com/afrodeli/image/upload/v1611851240/Ela7UOOU8AIzbQb_g6nkf1.jpg"
          className={styles.programImg}
          // fluid={data.metrocard.childImageSharp.fluid}
          alt="Feeding Community Meals for Voters & Volunteers"
        />
        <h2 className={cx(styles.subTitle, "content-max-width")}>
          Other Ideas? We're open to collaborate
        </h2>
        <p>
          We’re always open to new ideas and new ways to support our local
          community. Please feel free to contact us with any questions,
          feedback, and partnership opportunities. You can email us at{" "}
          <a href="mailto:hello@feeding-community.org">
            hello@feeding-community.org
          </a>
        </p>
        <div>
        </div>
      </section>
    </div>
  </Layout>
)

export default ProgramsPage
